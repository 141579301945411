import * as yup from 'yup';

// Language definitions in the both type and JS context
export type Lang = 'en' | 'fr';
export const Lang = ['en', 'fr'] as const;
export const LangRegEx = /en|fr/;

// Helper method to extract a localized item
export const getItem = <T extends { language: Lang }>(
    items: T[],
    lang: Lang
) => {
    return items.find((i) => i.language === lang);
};

// Get the prop of a localized item
export const getItemProp = <T extends { language: Lang }>(
    items: T[],
    lang: Lang,
    key: keyof T
) => {
    const item = getItem(items, lang);
    return item?.[key];
};

//  Yup test method to validate if an items array contain an item for each language
export function validateItems(items: any) {
    try {
        for (const lang of Lang) {
            const item = items.find((i: any) => i.language === lang);
            if (!item) {
                return false;
            }
        }
        return true;
    } catch (e) {
        return false;
    }
}

// regEx to validate an email
export function validateEmail(email: string) {
    /* eslint-disable-next-line no-useless-escape */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Utility method to modify an existing type
export type Modify<T, R> = Omit<T, keyof R> & R;
