import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import * as yup from 'yup';
import { NotificationManager } from '../components';
import { WithNetworkConcurrency } from './with-network-concurrency';
import { Lang, LangRegEx } from '../utils';
import { HttpMethod } from '../types';

const offerItems = yup
    .object({
        title: yup.string(),
    })
    .required()
    .shape({
        language: yup.string<Lang>().required().matches(LangRegEx),
    });

export const YupOffer = yup.object({
    id: yup.number().required(),
    items: yup.array(offerItems).required(),
});

export const YupOfferUpsertPayload = yup.object({
    items: yup.array(offerItems).required(),
    canBeDeleted: yup.boolean().required(),
});

const YupOfferList = yup.object({
    total: yup.number().required(),
    results: yup.array(YupOffer).default([]),
});

const YupPaginatedOfferList = YupOfferList.shape({
    page: yup.number().required(),
    perPage: yup.number().required(),
});

export type OfferList = yup.InferType<typeof YupOfferList>;
export type PaginatedOfferList = yup.InferType<typeof YupPaginatedOfferList>;
export type Offer = yup.InferType<typeof YupOffer>;
export type OfferUpsertPayload = yup.InferType<typeof YupOfferUpsertPayload>;

export type OfferSearchParams = {
    perPage?: number;
    page?: number;
    q?: string;
};

export class OfferStore extends WithNetworkConcurrency {
    @observable offers: OfferList | null = null;
    @observable isFetchingOffers = false;
    @observable paginatedOffers: PaginatedOfferList | null = null;
    @observable isFetchingPaginatedCount = 0;

    offersMap: Map<number, Offer> = new Map();
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    getOffersById(this: OfferStore, id: number) {
        return this.offersMap.get(id);
    }

    @action
    setOffers(this: OfferStore, offers: OfferList) {
        this.offers = offers;

        for (const category of offers.results) {
            this.offersMap.set(category.id, category);
        }
    }

    @action
    setPaginatedOffers(this: OfferStore, offers: PaginatedOfferList) {
        this.paginatedOffers = offers;
    }

    @action
    async fetchOffers(this: OfferStore) {
        if (this.isFetchingOffers || this.offers) {
            return;
        }

        this.isFetchingOffers = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotional-offers?all=true',
            },
            YupOfferList
        );
        this.isFetchingOffers = false;

        if (!response.err) {
            this.setOffers(response.data);
        }
    }

    @action
    async fetchPaginatedOffers(
        this: OfferStore,
        searchParams: OfferSearchParams
    ) {
        const tag = this.getTag();
        this.isFetchingPaginatedCount++;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/promotional-offers',
                params: searchParams,
            },
            YupPaginatedOfferList
        );
        this.isFetchingPaginatedCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.setPaginatedOffers(response.data);
        }
    }

    @action
    async fetchOffer(this: OfferStore, offerId: number) {
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: `/promotional-offers/${offerId}`,
            },
            YupOfferUpsertPayload,
            { stripUnknown: true }
        );

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async updateOffer(
        this: OfferStore,
        offer: OfferUpsertPayload,
        offerId: number
    ) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            url: `/promotional-offers/${offerId}`,
            data: offer,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Offer updated');
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async createOffer(this: OfferStore, offer: OfferUpsertPayload) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.POST,
            url: `/promotional-offers`,
            data: offer,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Offer created');
            return response.data;
        } else {
            throw response.err;
        }
    }

    @action
    async deleteOffer(this: OfferStore, offerId: number) {
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.DELETE,
            url: `/promotional-offers/${offerId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess('Offer deleted');
        }

        return response;
    }
}
