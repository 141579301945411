import React from 'react';
import style from './style.module.scss';
import { observer } from 'mobx-react';
import {
    Table,
    Tag,
    Button,
    Modal,
    Spin,
    Descriptions,
    Tooltip,
    Avatar,
    Popover,
    Space,
    Row,
    InputNumber,
} from 'antd';
import {
    BigModal,
    PageHeader,
    Pagination,
    SearchInput,
    UserProfile,
} from '../../components';
import { ColumnProps } from 'antd/es/table';
import { useAuthStore, useClientStore } from '../../contexts/mobx';
import { useQueryParams } from '../../utils/use-query-params';
import { SearchParams, Client } from '../../stores/client';
import { ReactComponent as MoreIcon } from '../../icons/more-horizontal.svg';
import { coucheTardRed } from '../../utils/const';
import { ReactComponent as TicketIcon } from '../../icons/Ticket.svg';
import { UserCouponsTab } from '../../components/user-profile/user-coupons-tab';
import { Text } from '../../utils/text';
import moment from 'moment';
import useWindowDimensions from '../../utils/display';
import { ReactComponent as CheckIcon } from '../../icons/CheckCircle.svg';
import { ReactComponent as EmptyCheckIcon } from '../../icons/CheckCircleEmpty.svg';
import UnblockPaymentModal from './components/modals/UnblockPaymentModal';
import UnblockCarwashModal from './components/modals/UnblockCarwashModal';
import ResetPasswordModal from './components/modals/ResetPasswordModal';
import ValidateClientEmailModal from './components/modals/ValidateClientEmailModal';
import DeleteClientModal from './components/modals/DeleteClientModal';
import UpdateExternalIdentifiersModal from './components/modals/UpdateExternalIdentifiersModal';

export const ClientsPage = observer(() => {
    const clientStore = useClientStore();
    const authStore = useAuthStore();
    const { isManager } = authStore;
    const [params, setParams, setParamsAndGoToFirstPage] = useQueryParams<
        SearchParams
    >();

    const [showResetPwdModal, setResetPwdModal] = React.useState(false);
    const [showUnblockCarwashModal, setUnblockCarwashModal] = React.useState(
        false
    );
    const [
        showUnblockPaymentMethodsModal,
        setUnblockPaymentMethodsModal,
    ] = React.useState(false);
    const [validateEmailModal, setValidateEmailModal] = React.useState(false);
    const [selectedClient, setSelectedClient] = React.useState<Client | null>(
        null
    );
    const [searchValue, setSearchValue] = React.useState<string>();

    const [userProfile, setUserProfile] = React.useState(false);

    const [userProfileData, setUserProfileData] = React.useState<Client>();

    const [paymentObject, setPaymentObject] = React.useState({
        loading: clientStore.isFetchingPaymentMethod,
        data: clientStore.paymentMethod,
    });

    const [deviceAppVersion, setDeviceAppVersion] = React.useState({
        loading: clientStore.isFetchingPaymentMethod,
        data: clientStore.deviceAppVersion,
    });

    const [clientCouponsModal, setClientCouponsModal] = React.useState(false);

    const [nhlGameModal, setNhlGameModal] = React.useState(false);

    const [generatePin, setGeneratePin] = React.useState(1);
    const [pins, setPins] = React.useState<string[]>();
    const [newPinsGenerated, setNewPinsGenerated] = React.useState(false);

    const [showDeleteClientModal, setDeleteClientModal] = React.useState(false);
    const [
        showUpdateIdentifiersModal,
        setShowUpdateIdentifiersModal,
    ] = React.useState(false);

    const firstRender = React.useRef(false);

    React.useEffect(() => {
        clientStore.fetchList(params);
        setSearchValue(params.q);
    }, [clientStore, params]);

    React.useEffect(() => {
        if (clientStore.isSendingResetEmail === false) {
            setResetPwdModal(false);
        }
    }, [clientStore.isSendingResetEmail]);

    React.useEffect(() => {
        if (clientStore.isUnblockingDevice === false) {
            setUnblockCarwashModal(false);
        }
    }, [clientStore.isUnblockingDevice]);

    React.useEffect(() => {
        if (firstRender.current) {
            if (userProfileData) {
                clientStore.getPaymentMethod({ clientId: userProfileData.id });
                clientStore.fetchClientDeviceAppVersion(userProfileData.id);
            }
        } else {
            firstRender.current = true;
        }
    }, [userProfileData, clientStore]);

    React.useEffect(() => {
        setPaymentObject({
            loading: clientStore.isFetchingPaymentMethod,
            data: clientStore.paymentMethod,
        });
        setDeviceAppVersion({
            loading: clientStore.isFetchingPaymentMethod,
            data: clientStore.deviceAppVersion,
        });
    }, [
        clientStore.deviceAppVersion,
        clientStore.isFetchingPaymentMethod,
        clientStore.paymentMethod,
    ]);

    const closeUserProfile = () => {
        setUserProfile((current) => !current);
    };

    const showUserProfile = (user: Client) => {
        setUserProfileData(user);
        !userProfile && setUserProfile((current) => !current);
    };

    const handleResetPassword = () => {
        if (!userProfileData?.email) {
            Modal.error({ title: "This client doesn't have an email" });
        } else {
            setSelectedClient(userProfileData);
            setResetPwdModal(true);
        }
    };

    const unlockCarwash = React.useCallback((client: Client) => {
        if (!client.liquidBarcodeId) {
            Modal.error({
                title: "This client doesn't have an Liquid Barcode Id",
            });
        }
        if (client.liquidBarcodeId) {
            if (!client.carwashDeviceLockedUntil) {
                Modal.error({
                    title: 'This client is already unlocked',
                });
            } else {
                setSelectedClient(client);
                setUnblockCarwashModal(true);
            }
        }
    }, []);

    const unlockPaymentMethodAction = React.useCallback(
        async (client: Client) => {
            setSelectedClient(client);
            setUnblockPaymentMethodsModal(true);
        },
        []
    );

    const handleDeleteClient = React.useCallback(async (client: Client) => {
        setSelectedClient(client);
        setDeleteClientModal(true);
    }, []);

    const handleClientCoupons = React.useCallback((client: Client) => {
        setClientCouponsModal(true);
        setUserProfileData(client);
    }, []);

    const handleNhlGame = React.useCallback(async (client: Client) => {
        const isPlayingNhlGame = await clientStore.getNhlGameProfil({
            clientId: client.id,
        });

        if (isPlayingNhlGame) {
            setNhlGameModal(true);
            setUserProfileData(client);
        }
    }, []);

    const generateSacPin = async (clientId: number) => {
        const newPins = await clientStore.createSacPins(clientId, generatePin);

        if (newPins) {
            setNewPinsGenerated(true);
            setPins(newPins.pins);
        }
    };

    const validateEmail = React.useCallback((client: Client) => {
        if (!client.email) {
            Modal.error({
                title: "This client doesn't have an email",
            });
        } else {
            setSelectedClient(client);
            setValidateEmailModal(true);
        }
    }, []);

    const columns = React.useMemo<ColumnProps<Client>[]>(
        () => [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                width: 80,
                ellipsis: true,
            },
            {
                title: 'Name',
                key: 'name',
                width: 150,
                ellipsis: true,
                render: (_: unknown, record: Client) => (
                    <>
                        <Avatar
                            shape="circle"
                            style={{
                                verticalAlign: 'middle',
                                backgroundColor: coucheTardRed,
                            }}
                            size={30}
                            src={record.picture!}
                        >
                            {record.firstName?.charAt(0).toLocaleUpperCase()}
                            {record.lastName?.charAt(0).toLocaleUpperCase()}
                        </Avatar>
                        <Tooltip
                            placement="topLeft"
                            title={`${record.firstName} ${record.lastName}`}
                        >
                            <span className={style.name}>
                                {record.firstName} {record.lastName}
                            </span>
                        </Tooltip>
                    </>
                ),
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 150,
                ellipsis: true,
                render: (_: unknown, record: Client) => (
                    <div className={style.contact_info}>
                        {record.email && (
                            <div className={style.confirmed}>
                                {record.isEmailConfirmed ? (
                                    <CheckIcon />
                                ) : (
                                    <EmptyCheckIcon stroke="grey" />
                                )}
                            </div>
                        )}
                        <Tooltip placement="topLeft" title={record.email}>
                            <span>{record.email}</span>
                        </Tooltip>
                    </div>
                ),
            },
            {
                title: (
                    <Tooltip placement="topLeft" title={'Language'}>
                        <span>Language</span>
                    </Tooltip>
                ),
                key: 'language',
                width: 60,
                ellipsis: true,
                render: (_: unknown, record: Client) => (
                    <Tag
                        color={record.language === 'fr' ? 'red' : 'orange'}
                        style={{ border: 'none' }}
                    >
                        {record.language}
                    </Tag>
                ),
            },
            {
                title: (
                    <Tooltip placement="topLeft" title={'Liquid Barcodes ID'}>
                        <span>Liquid Barcodes ID</span>
                    </Tooltip>
                ),
                key: 'liquidBarcodeId',
                width: 100,
                ellipsis: true,
                render: (_, record) => (
                    <Tooltip placement="topLeft" title={record.liquidBarcodeId}>
                        <span>{record.liquidBarcodeId}</span>
                    </Tooltip>
                ),
            },
            {
                title: 'NGRP ID',
                key: 'ngrpCustomerId',
                width: 100,
                ellipsis: true,
                render: (_, record) => (
                    <Tooltip placement="topLeft" title={record.ngrpCustomerId}>
                        <span>{record.ngrpCustomerId}</span>
                    </Tooltip>
                ),
            },
            {
                title: 'Birthday',
                key: 'birthday',
                width: 100,
                ellipsis: true,
                render: (_, record) => (
                    <Tooltip
                        placement="topLeft"
                        title={record.birthday?.format('LL')}
                    >
                        <span>{record.birthday?.format('LL')}</span>
                    </Tooltip>
                ),
            },
            {
                title: (
                    <Tooltip placement="topLeft" title={'Postal code'}>
                        <span>Postal code</span>
                    </Tooltip>
                ),
                key: 'postalCode',
                width: 100,
                ellipsis: true,
                dataIndex: 'postalCode',
            },
            {
                title: 'Phone',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber',
                width: 130,
                ellipsis: true,
                render: (_, record) => (
                    <div className={style.contact_info}>
                        {record.phoneNumber && (
                            <div className={style.confirmed}>
                                {record.isPhoneNumberConfirmed ? (
                                    <CheckIcon />
                                ) : (
                                    <EmptyCheckIcon stroke="grey" />
                                )}
                            </div>
                        )}
                        <Tooltip placement="topLeft" title={record.phoneNumber}>
                            <span>{record.phoneNumber}</span>
                        </Tooltip>
                    </div>
                ),
            },
            {
                key: 'more_options',
                width: 80,
                render: (_, record) => (
                    <div className={style.actions}>
                        <Tooltip
                            placement="top"
                            title={Text.clientsPage.reissueButton}
                        >
                            <div className={style.reissue}>
                                <button
                                    className={style.reissue_button}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClientCoupons(record);
                                    }}
                                >
                                    <TicketIcon />
                                </button>
                            </div>
                        </Tooltip>
                        <Popover
                            placement="left"
                            content={
                                <Space direction="vertical" size="small">
                                    {isManager && (
                                        <>
                                            <span
                                                className={style.clickable_span}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedClient(record);
                                                    setShowUpdateIdentifiersModal(
                                                        true
                                                    );
                                                }}
                                            >
                                                Update External Identifiers
                                            </span>
                                            <div
                                                className={style.divider}
                                            ></div>
                                        </>
                                    )}

                                    <span
                                        className={style.clickable_span}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            unlockCarwash(record);
                                        }}
                                    >
                                        {Text.clientsPage.quickAction.carwash}
                                    </span>
                                    <div className={style.divider}></div>
                                    <span
                                        className={style.clickable_span}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            validateEmail(record);
                                        }}
                                    >
                                        {Text.clientsPage.quickAction.email}
                                    </span>
                                    <div className={style.divider}></div>
                                    <span
                                        className={style.clickable_span}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleNhlGame(record);
                                        }}
                                    >
                                        {Text.clientsPage.quickAction.pins}
                                    </span>
                                    <div className={style.divider}></div>
                                    <span
                                        className={style.clickable_span}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            unlockPaymentMethodAction(record);
                                        }}
                                    >
                                        Unblock Payment Methods
                                    </span>
                                    <div className={style.divider}></div>
                                    <span
                                        className={style.clickable_span}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClient(record);
                                        }}
                                    >
                                        Delete Client
                                    </span>
                                </Space>
                            }
                            overlayClassName={style.popover_overlay}
                            title={null}
                            trigger="click"
                        >
                            <Button
                                className={style.more_options}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <MoreIcon />
                            </Button>
                        </Popover>
                    </div>
                ),
            },
        ],
        [isManager]
    );

    const handleSearchClick = () => setParamsAndGoToFirstPage('q', searchValue);
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        e.target.value !== ''
            ? setSearchValue(e.target.value)
            : (setParamsAndGoToFirstPage('q', e.target.value),
              setSearchValue(e.target.value));
    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.key === 'Enter' && setParamsAndGoToFirstPage('q', searchValue);

    const { windowHeight } = useWindowDimensions();

    return (
        <div className={style.clients_content}>
            <PageHeader title="Clients"></PageHeader>
            <div className={style.table_header}>
                <div className={style.query_box}>
                    <SearchInput
                        searchValue={searchValue}
                        onClickIcon={handleSearchClick}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                    />
                </div>
                <div className={style.pagination_container}>
                    <Pagination
                        className={style.pagination}
                        params={params}
                        meta={clientStore.list}
                        onChange={(pageNumber) =>
                            setParams({ page: pageNumber })
                        }
                        onPageSizeChange={(size) =>
                            setParams({ perPage: size })
                        }
                    />
                </div>
            </div>
            <Table<Client>
                rowKey="id"
                columns={columns}
                scroll={{ y: windowHeight - 150, x: 900 }}
                dataSource={clientStore.list?.results}
                loading={clientStore.fetchingCount > 0}
                bordered
                pagination={false}
                onRow={(r) => ({
                    onClick: () => showUserProfile(r),
                })}
            />

            <UnblockCarwashModal
                isVisible={showUnblockCarwashModal}
                clientId={selectedClient?.id}
                deviceFirebaseId={selectedClient?.carwashSubscriptionDevice}
                deviceLockedUntil={selectedClient?.carwashDeviceLockedUntil}
                onUnblock={(id) => clientStore.unblockCarwashDevice(id)}
                onClose={() => setUnblockCarwashModal(false)}
                isUnblocking={clientStore.isUnblockingDevice}
            />

            <ResetPasswordModal
                isVisible={showResetPwdModal}
                clientEmail={selectedClient?.email}
                onSend={(email) => clientStore.sendResetPasswordEmail(email)}
                onClose={() => setResetPwdModal(false)}
                isSending={clientStore.isSendingResetEmail}
            />

            <UnblockPaymentModal
                isVisible={showUnblockPaymentMethodsModal}
                client={selectedClient}
                onUnblock={(clientId) => {
                    clientStore.unblockPaymentMethod(clientId);
                    setUnblockPaymentMethodsModal(false);
                }}
                onClose={() => setUnblockPaymentMethodsModal(false)}
            />

            <ValidateClientEmailModal
                isVisible={validateEmailModal}
                clientId={selectedClient?.id}
                clientEmail={selectedClient?.email}
                isSending={clientStore.isSendingValidationEmail}
                onValidate={(id) => clientStore.validateEmail(id)}
                onClose={() => setValidateEmailModal(false)}
            />

            <DeleteClientModal
                isVisible={showDeleteClientModal}
                client={selectedClient}
                onDelete={(id) => clientStore.deleteClient(id)}
                onClose={() => setDeleteClientModal(false)}
            />

            {isManager && (
                <UpdateExternalIdentifiersModal
                    isVisible={showUpdateIdentifiersModal}
                    client={selectedClient}
                    onClose={() => setShowUpdateIdentifiersModal(false)}
                />
            )}

            {nhlGameModal ? (
                clientStore.isFetchingNhlGameProfil ? (
                    <Spin />
                ) : (
                    <BigModal
                        onClose={() => {
                            setNhlGameModal(false);
                            setNewPinsGenerated(false);
                            setGeneratePin(1);
                            setPins(undefined);
                        }}
                        header={
                            <div className={style.coupons_header}>
                                <div className={style.header_description}>
                                    {Text.bigModal.molsonGame.registerd}{' '}
                                    {moment(
                                        clientStore.nhlGameProfil?.createdAt
                                    ).format('LLL')}
                                </div>
                                <div className={style.user_name}>
                                    {userProfileData?.firstName}{' '}
                                    {userProfileData?.lastName}
                                </div>
                            </div>
                        }
                    >
                        {newPinsGenerated ? (
                            <Descriptions
                                bordered
                                size="small"
                                title={Text.clientsPage.description.newPins}
                                className={style.row_style}
                                layout={'vertical'}
                            >
                                <Descriptions.Item
                                    label={
                                        Text.clientsPage.description
                                            .newPinsCreated
                                    }
                                >
                                    {pins
                                        ? pins.map((p) => (
                                              <span
                                                  key={p}
                                                  className={style.pins_style}
                                              >
                                                  {p}{' '}
                                              </span>
                                          ))
                                        : null}
                                </Descriptions.Item>
                            </Descriptions>
                        ) : (
                            <>
                                <Row
                                    justify="start"
                                    className={style.description_style}
                                >
                                    {Text.clientsPage.description.grantPins}
                                </Row>

                                <Row
                                    justify="start"
                                    className={style.description_style}
                                >
                                    {userProfileData ? (
                                        clientStore.isFetchingNhlGameProfilPins ? (
                                            <Spin />
                                        ) : (
                                            <>
                                                <InputNumber
                                                    min={1}
                                                    max={10}
                                                    defaultValue={1}
                                                    onChange={(e) => {
                                                        setGeneratePin(
                                                            Number(e)
                                                        );
                                                    }}
                                                />
                                                <Button
                                                    className={
                                                        style.description_style
                                                    }
                                                    onClick={() =>
                                                        generateSacPin(
                                                            userProfileData.id
                                                        )
                                                    }
                                                >
                                                    {
                                                        Text.clientsPage
                                                            .description.create
                                                    }
                                                </Button>
                                            </>
                                        )
                                    ) : null}{' '}
                                </Row>
                            </>
                        )}
                        <Descriptions
                            bordered
                            size="small"
                            title={`Participation #${clientStore.nhlGameProfil?.participationId}`}
                            className={style.row_style}
                            layout={'vertical'}
                        >
                            <Descriptions.Item
                                label={Text.clientsPage.description.team}
                            >
                                <Avatar
                                    src={
                                        clientStore.nhlGameProfil?.teamLogo ??
                                        undefined
                                    }
                                />{' '}
                                {clientStore.nhlGameProfil?.team}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={Text.clientsPage.description.points}
                            >
                                {clientStore.nhlGameProfil?.points}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={Text.clientsPage.description.rank}
                            >
                                {clientStore.nhlGameProfil?.rank}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            bordered
                            size="small"
                            title={Text.clientsPage.description.predictions}
                            className={style.row_style}
                            layout={'vertical'}
                        >
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description
                                        .predictionsTotal
                                }
                            >
                                {
                                    clientStore.nhlGameProfil?.predictions
                                        .totalPredictions
                                }
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description
                                        .predictionsWithPins
                                }
                            >
                                {
                                    clientStore.nhlGameProfil?.predictions
                                        .predictionWithPins
                                }
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description
                                        .predictionsWithoutPins
                                }
                            >
                                {
                                    clientStore.nhlGameProfil?.predictions
                                        .predictionWithoutPins
                                }
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            bordered
                            size="small"
                            title={Text.clientsPage.description.pins}
                            className={style.row_style}
                            layout={'vertical'}
                        >
                            <Descriptions.Item
                                label={Text.clientsPage.description.totalPins}
                            >
                                {clientStore.nhlGameProfil?.pins.totalPins}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description.totalPinsUsed
                                }
                            >
                                {
                                    clientStore.nhlGameProfil?.pins
                                        .totalPinsBurned
                                }
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description
                                        .totalPinsPending
                                }
                            >
                                {
                                    clientStore.nhlGameProfil?.pins
                                        .totalPinsPending
                                }
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description
                                        .totalPinsCreatedSac
                                }
                            >
                                {clientStore.nhlGameProfil?.pins.totalPinsSac}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={
                                    Text.clientsPage.description
                                        .totalPinsValidated
                                }
                            >
                                {
                                    clientStore.nhlGameProfil?.pins
                                        .totalPinsValidated
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </BigModal>
                )
            ) : null}
            {clientCouponsModal && (
                <BigModal
                    onClose={() => setClientCouponsModal(false)}
                    header={
                        <div className={style.coupons_header}>
                            <div className={style.header_description}>
                                {Text.bigModal.reissueCoupon.headertext}
                                {userProfileData?.id}
                            </div>
                            <div className={style.user_name}>
                                {userProfileData?.firstName}{' '}
                                {userProfileData?.lastName}
                            </div>
                        </div>
                    }
                >
                    <UserCouponsTab
                        clientObject={userProfileData}
                        hasSearchBar={true}
                    />
                </BigModal>
            )}
            <div
                className={
                    userProfile
                        ? `${style.user_profile} ${style.active}`
                        : `${style.user_profile}`
                }
            >
                <UserProfile
                    onCloseClick={closeUserProfile}
                    clientObject={userProfileData}
                    resetPassword={handleResetPassword}
                    paymentMethod={paymentObject}
                    deviceAppVersion={deviceAppVersion}
                />
            </div>
        </div>
    );
});
