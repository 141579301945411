import React from 'react';
import {
    Input,
    Select,
    DatePicker,
    Button,
    Switch,
    Spin,
    Divider,
    Tooltip,
    Statistic,
    Card,
} from 'antd';
import Papa from 'papaparse';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import {
    useCategoryStore,
    usePromotionStore,
    useOfferStore,
    useAuthStore,
} from '../../contexts/mobx';
import { Promotion, YupPromotion } from '../../stores/promotion';
import moment from 'moment';
import {
    ImageInput,
    ErrorAwareLabel,
    ModalManager,
    ButtonFileInput,
    NotificationManager,
    NavigationHeader,
    CmsUserHistoryHeader,
} from '../../components';
import style from './style.module.scss';
import * as yup from 'yup';
import get from 'lodash/get';
import compose from 'lodash/fp/compose';
import { getPromotionsTypeName } from '../../utils/promotion-types';
import { PromotionTypes } from '../../utils/const';
import produce, { setAutoFreeze } from 'immer';
import { useNavigation } from '../../utils/use-navigation';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Tabs, Tag, Table } from 'antd';
import { Grant, AnonymousGrant } from '../../stores/promotion-grant';
import { usePromotionGrantStore } from '../../contexts/mobx';
import { ColumnProps } from 'antd/es/table';
import star from '../../icons/Icon_Coupons_Gagnants_1_1X.svg';
import { RichTextEditor } from '../../components';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
    linkDecorator,
    RichProvider,
} from '../../components/rich-text-editor/helper';
import { downloadCSVFile } from '../../utils/csv';
import { CsvContent } from '../../types';
import {
    AcvtCanvas,
    Acvt,
    AcvtBottomTabBar,
    AcvtPromotionPage,
} from '../../components/advanced-ct-visualisation-tool';
import {
    AcvtPromotion,
    AcvtSupportedLanguages,
    AcvtTabs,
} from '../../types/acvt';
import { Text } from '../../utils/text';

setAutoFreeze(false);

// Validation for the expected params
const YupParams = yup.object({
    action: yup
        .string()
        .oneOf([
            Text.promotionPage.params.action.create,
            Text.promotionPage.params.action.edit,
            Text.promotionPage.params.action.duplicate,
        ])
        .required(),
    id: yup
        .string()
        .test('shouldBeDefined', 'Id should be defined', function (v) {
            const action = this.resolve(yup.ref('action'));
            return !(
                v === undefined &&
                action !== Text.promotionPage.params.action.create
            );
        }),
});

type Params = yup.InferType<typeof YupParams>;

type PromotionForm = Omit<Partial<Promotion>, 'items'> & {
    items: {
        title: string;
        subTitle?: string;
        description: EditorState;
        thumbnail?: string | null;
        background?: string | null;
        outsideActivatableHours?: string | null;
        language: 'en' | 'fr';
    }[];
};

const converter = (promo: Promotion): PromotionForm => {
    return {
        ...promo,
        items: promo.items.map((p) => {
            const { contentBlocks, entityMap } = htmlToDraft(
                p.description ?? ''
            );
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            return {
                ...p,
                description: EditorState.createWithContent(
                    contentState,
                    linkDecorator
                ),
            };
        }),
    };
};

const inverseConverter = (promo: PromotionForm): unknown => {
    const hashConfig = {
        trigger: '#',
        separator: ' ',
    };

    return {
        ...promo,
        items: promo.items.map((x) => {
            const rawContentState = convertToRaw(
                x.description.getCurrentContent()
            );
            const markup = draftToHtml(rawContentState, hashConfig);
            return {
                ...x,
                description: markup,
            };
        }),
    };
};

const doNothing = <T,>(arg: T) => arg;

const removeDates = produce((promotion: PromotionForm) => {
    delete promotion.startDate;
    delete promotion.endDate;
    delete promotion.activatableDate;
    delete promotion.inactivatableDate;
});

// Generate hours of the day, from 1h to 24h
const hours = [...new Array(24)].map((_, i) => i + 1);

const columnsNormal: ColumnProps<Grant>[] = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: (_: unknown, record: Grant) => `${record.client.email}`,
    },
    {
        title: 'Name',
        key: 'name',
        render: (_: unknown, record: Grant) =>
            `${record.client.firstName} ${record.client.lastName}`,
    },
    {
        title: 'Language',
        key: 'language',
        render: (_: unknown, record: Grant) => (
            <Tag color={record.client.language === 'fr' ? 'blue' : 'magenta'}>
                {record.client.language}
            </Tag>
        ),
    },
    {
        title: 'Number of wins',
        key: 'numberOfWins',
        render: (_: unknown, record: Grant) => (
            <div className={style.star_img}>
                <img src={star} alt="star icon" />
                <span className={style.normal}>{record.cnt}</span>
            </div>
        ),
    },
];

const columnsAnonymus: ColumnProps<AnonymousGrant>[] = [
    {
        title: 'Email',
        key: 'email',
        render: (_: unknown, record: AnonymousGrant) => (
            <Tag>{record.email}</Tag>
        ),
    },
    {
        title: 'Claimed by',
        key: 'claimedBy',
        render: (_: unknown, record: AnonymousGrant) => (
            <div>
                <span>{record.claimByGrant?.client.fullName}</span>
            </div>
        ),
    },
];

export const PromotionPage = observer(() => {
    const [isFetching, setIsFetching] = React.useState<boolean>(false);
    const [isFetchingAnon, setIsFetchingAnon] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const store = usePromotionGrantStore();
    const categoryStore = useCategoryStore();
    const promotionStore = usePromotionStore();
    const offerStore = useOfferStore();
    const params = useParams<Params>();
    const navigation = useNavigation();
    const [isUploading, setIsUploading] = React.useState(false);
    const [grants, setGrants] = React.useState<Grant[] | null>(null);
    const [anonymousGrants, setAnonymousGrants] = React.useState<
        AnonymousGrant[] | null
    >(null);
    const [fileSelected, setFileSelected] = React.useState<boolean>(false);
    const authStore = useAuthStore();

    const { hasManagementAccess } = authStore;

    if (!hasManagementAccess) {
        navigation.goToHome();
    }

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToPromotionCreate();
    }

    const formik = useFormik<PromotionForm>({
        initialValues: {
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            id: 0,
            isForMajor: false,
            isForCTConnect: false,
            isForCarwash: false,
            isActive: true,
            isVisibleOnHomePage: true,
            isPrivate: false,
            activationCode: '',
            quantity: 0,
            type: 'in_store',
            activatableDate: null,
            inactivatableDate: null,
            startPeriod: null,
            endPeriod: null,
            category: undefined,
            categoryId: undefined,
            promotionalOffer: null,
            promotionalOfferId: null,
            canBeDeleted: false,
            isNhlGamePromotion: false,
            discountValue: 0,
            originalPrice: 0,
            rebateAmount: 0,
            totalActivations: 0,
            totalGrants: 0,
            items: [
                {
                    title: '',
                    subTitle: '',
                    description: EditorState.createEmpty(linkDecorator),
                    thumbnail: '',
                    outsideActivatableHours: '',
                    language: 'en',
                },
                {
                    title: '',
                    subTitle: '',
                    description: EditorState.createEmpty(linkDecorator),
                    thumbnail: '',
                    outsideActivatableHours: '',
                    language: 'fr',
                },
            ],
            cmsUserHistory: [],
            inTestingCampaign: false,
        },
        validateOnMount: true,
        validationSchema: YupPromotion,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    React.useEffect(() => {
        categoryStore.fetchActiveCategories();
    }, [categoryStore]);

    React.useEffect(() => {
        offerStore.fetchOffers();
    }, [offerStore]);

    React.useEffect(() => {
        const isDuplicate =
            params.action === Text.promotionPage.params.action.duplicate;
        if (
            params.action === Text.promotionPage.params.action.edit ||
            isDuplicate
        ) {
            setIsFetching(true);
            promotionStore
                .fetchSinglePromotion(Number(params.id!))
                .then((promotion) => {
                    const modifiedPromotion = compose(
                        isDuplicate ? removeDates : doNothing,
                        converter
                    )(promotion);
                    formik.setValues(modifiedPromotion);
                })
                .catch(() => navigation.replaceToPromotionCreate())
                .finally(() => setIsFetching(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionStore]);

    //
    React.useEffect(() => {
        if (params.action === Text.promotionPage.params.action.duplicate) {
            formik.setValues(removeDates(formik.values));
        }
    }, [params.action]);

    // The type inference is not working well for nested error schema. So we are
    // going to cheat a little bit using lodash
    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return (
            hasError &&
            (isTouched ||
                params.action !== Text.promotionPage.params.action.create)
        );
    };

    /**
     * Create a new promotion
     */
    const createPromotion = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;

        const unknownPromo = inverseConverter(formik.values);
        if (YupPromotion.isValidSync(unknownPromo)) {
            const success = await promotionStore.createPromotion(unknownPromo);
            if (success) {
                navigation.replaceToPromotions();
            }
        }
    };

    /**
     * Update promotion
     */
    const updatePromotion = async () => {
        formik.values.cmsUserEmail = authStore.claims?.email || null;

        const unknownPromo = inverseConverter(formik.values);
        if (YupPromotion.isValidSync(unknownPromo)) {
            promotionStore.modifyPromotion(unknownPromo); // make another converter but the other way around
        }
    };

    /**
     * Set a new category
     * @param categoryId
     */
    const setCategory = (categoryId: number) => {
        const values = produce(formik.values, (draft) => {
            draft.categoryId = categoryId;
            draft.category = categoryStore.getActiveCategoryById(categoryId);
        });
        formik.setFieldTouched('categoryId', true);
        formik.setValues(values);
    };

    /**
     * Confirm promotion deletion
     */
    const onDeleteConfirm = async () => {
        setIsDeleting(true);
        const response = await promotionStore.delete(Number(params.id!));
        setIsDeleting(false);

        if (!response.err) {
            navigation.replaceToPromotions();
        }
    };

    /**
     * The allowed values of the isForCTConnect change according to the promotion type.
     * - If the promotionType is coupon, the value should be a boolean.
     * - If the promotionType is in_store, the value should be null.
     *
     * @param type
     */
    const onTypeChange = (type: PromotionTypes) => {
        formik.setFieldValue('type', type);
        if (type === 'coupon') {
            formik.setFieldValue(
                'isForCTConnect',
                formik.values.isForCTConnect ?? false
            );
        }
    };

    const disableDate = (type: string) => {
        let value = true;
        if (
            formik.values.startDate !== null &&
            formik.values.startDate !== undefined
        ) {
            value = false;
        }
        return value;
    };

    const fetchGrants = React.useCallback(() => {
        setIsFetching(true);
        setIsFetchingAnon(true);
        store
            .fetchGrants(Number(params.id))
            .then(setGrants)
            .catch(console.log)
            .finally(() => setIsFetching(false));

        store
            .fetchAnonymousGrants(Number(params.id))
            .then(setAnonymousGrants)
            .catch(console.log)
            .finally(() => setIsFetchingAnon(false));
    }, [params.id]);

    if (params.action !== Text.promotionPage.params.action.create) {
        React.useEffect(() => {
            fetchGrants();
        }, []);
    }

    const onFileSelected = (file: File) => {
        Papa.parse<unknown>(file, {
            complete: async (results) => {
                try {
                    const emails = results.data.flat().filter((e) => !!e);
                    setIsUploading(true);
                    await store.uploadGrants(Number(params.id), emails);
                    fetchGrants();
                } catch (e) {
                    console.error(e);
                } finally {
                    setIsUploading(false);
                }
            },
            error: () => {
                NotificationManager.showError('Error parsing the file');
            },
        });
        setFileSelected(true);
    };

    const disableUpload = () => {
        const startDate = formik.values.startDate;
        const currentDate = moment();

        if (startDate !== undefined) {
            return currentDate < startDate;
        }
        return false;
    };

    /**
     * Download the list of winners
     */
    const downloadWinners = () => {
        const flattenGrants: CsvContent =
            grants?.map((grant) => ({
                Type: 'Registered',
                'Grant Id': grant.id,
                'Client Id': grant.clientId,
                Email: grant.client.email,
                'Full Name': grant.client.fullName,
                Language: grant.client.language,
                Count: grant.cnt,
            })) ?? [];
        anonymousGrants?.forEach((anonymousGrant) => {
            flattenGrants?.push({
                Type: 'Anonymous',
                Count: 1,
                Email: anonymousGrant.email,
            });
        });

        return downloadCSVFile(flattenGrants, 'winners');
    };

    const promotionId =
        formik.values.id !== undefined &&
        params.action !== Text.promotionPage.params.action.duplicate
            ? formik.values.id
            : 0;

    const [lang, setLang] = React.useState<AcvtSupportedLanguages>('en');
    const [acvtItemsIndex, setAcvtItemsIndex] = React.useState(0);
    const [selectedTab, setSelectedTab] = React.useState<AcvtTabs>('promos');
    const [isCardOpened, setIsCardOpened] = React.useState({
        homePromoCard: false,
        homeGameCard: false,
        promoCard: true,
        gameCard: false,
    });

    React.useEffect(() => {
        lang === 'fr' ? setAcvtItemsIndex(1) : setAcvtItemsIndex(0);
    }, [lang, formik.values.items]);

    const changeLanguage = (language: AcvtSupportedLanguages) => {
        setLang(language);
    };

    const promoObjectData: AcvtPromotion = {
        id: formik.values.id,
        type: formik.values.type,
        thumbnail: formik.values.items[acvtItemsIndex as number].thumbnail!,
        background: formik.values.items[acvtItemsIndex as number].background!,
        category: {
            id: acvtItemsIndex,
            title: formik.values.category?.items[acvtItemsIndex].title,
        },
        title: formik.values.items[acvtItemsIndex as number].title,
        startDate: formik.values.startDate,
        endDate: formik.values.endDate,
        activatableDate: formik.values.activatableDate,
        inactivatableDate: formik.values.inactivatableDate,
        startPeriod: formik.values.startPeriod,
        endPeriod: formik.values.endPeriod,
        description: draftToHtml(
            convertToRaw(
                formik.values.items[
                    acvtItemsIndex as number
                ].description.getCurrentContent()
            )
        ),
        promotionalOffer: {
            id: acvtItemsIndex,
            title: formik.values.promotionalOffer?.items[acvtItemsIndex].title,
        },
    };

    const transformId = params.id ? Number(params.id) : undefined;

    return (
        <div className={style.container}>
            <div className={style.page_title_container}>
                <NavigationHeader
                    id={transformId}
                    action={params.action}
                    onBackClick={() => window.history.back()}
                    onSaveClick={updatePromotion}
                    onCreateClick={createPromotion}
                    disabled={!formik.isValid}
                    isLoading={promotionStore.isUpsertingPromotion}
                    onDeleteClick={ModalManager.showDeleteModal({
                        onConfirm: onDeleteConfirm,
                    })}
                    onDuplicateClick={
                        promotionId !== 0
                            ? () =>
                                  navigation.goToPromotionDuplicate(promotionId)
                            : undefined
                    }
                    isDeleting={isDeleting}
                    canBeDeleted={true}
                    type="Promotion"
                />
            </div>
            <form
                style={{
                    display: isFetching ? 'none' : 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={style.config_viewer_split}>
                    <div className={style.left_side}>
                        {formik.values.cmsUserHistory && (
                            <CmsUserHistoryHeader
                                cmsUserHistory={formik.values.cmsUserHistory}
                            />
                        )}
                        {isFetching && <Spin />}
                        <h3 className={style.sectionHeader}>Config</h3>

                        {/* Is active */}
                        <div className={style.input_row}>
                            <div className={style.input_row}>
                                <Card bodyStyle={{ padding: '0.5rem' }}>
                                    <span className="left_label">Active</span>
                                    <Switch
                                        checked={formik.values.isActive}
                                        onChange={(e) =>
                                            formik.setFieldValue('isActive', e)
                                        }
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Card>
                            </div>
                            <div className={style.input_row}>
                                <Card bodyStyle={{ padding: '0.5rem' }}>
                                    <span className="left_label">Homepage</span>
                                    <Switch
                                        checked={
                                            formik.values.isVisibleOnHomePage
                                        }
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'isVisibleOnHomePage',
                                                e
                                            )
                                        }
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked
                                    />
                                </Card>
                            </div>
                            <div className={style.input_row}>
                                <Card bodyStyle={{ padding: '0.5rem' }}>
                                    <span className="left_label">18+</span>
                                    <Switch
                                        checked={formik.values.isForMajor}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'isForMajor',
                                                e
                                            )
                                        }
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Card>
                            </div>
                            <div className={style.input_row}>
                                <Card bodyStyle={{ padding: '0.5rem' }}>
                                    <span className="left_label">
                                        Carwash Only
                                    </span>
                                    <Switch
                                        checked={formik.values.isForCarwash}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'isForCarwash',
                                                e
                                            )
                                        }
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Card>
                            </div>
                            <div className={style.input_row}>
                                <Card bodyStyle={{ padding: '0.5rem' }}>
                                    <span className="left_label">
                                        AB Testing
                                    </span>
                                    <Switch
                                        checked={
                                            formik.values.inTestingCampaign
                                        }
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'inTestingCampaign',
                                                e
                                            )
                                        }
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Card>
                            </div>
                            {formik.values.type === 'coupon' && (
                                <div className={style.input_row}>
                                    <Card bodyStyle={{ padding: '0.5rem' }}>
                                        <span className="left_label">
                                            Available in CT Connect{' '}
                                        </span>
                                        <Switch
                                            checked={
                                                formik.values.isForCTConnect ??
                                                false
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'isForCTConnect',
                                                    e
                                                )
                                            }
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                        />
                                    </Card>
                                </div>
                            )}
                        </div>

                        {formik.values.inTestingCampaign && (
                            <>
                                <h3 className={style.sectionHeader}>
                                    AB Testing
                                </h3>
                                <Card>
                                    <div className={style.input_row}>
                                        <div className={style.top_label_input}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span>Original Price</span>
                                                <Tooltip
                                                    title="Original Price is the item's pre-promotion cost."
                                                    style={{ fontSize: '18px' }}
                                                >
                                                    <InfoCircleOutlined
                                                        style={{
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <Input
                                                type="number"
                                                prefix="$"
                                                value={
                                                    formik.values
                                                        .originalPrice ?? 0
                                                }
                                                name="originalPrice"
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className={style.top_label_input}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span>Rebate Amount</span>
                                                <Tooltip
                                                    title="Price the customer will pay after discount"
                                                    style={{ fontSize: '18px' }}
                                                >
                                                    <InfoCircleOutlined
                                                        style={{
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <Input
                                                type="number"
                                                prefix="$"
                                                value={
                                                    formik.values
                                                        .rebateAmount ?? 0
                                                }
                                                name="rebateAmount"
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </>
                        )}

                        {/* Promotion Schedule */}

                        <h3 className={style.sectionHeader}>
                            Promotion schedule
                        </h3>
                        <Card>
                            <div className="dates_holder">
                                <div className="text_center inline_block">
                                    <div className="light_label">
                                        <ErrorAwareLabel
                                            text="Start"
                                            hasError={checkIfHasError(
                                                `startDate`
                                            )}
                                        />
                                        &nbsp;
                                        <Tooltip title="Promotion becomes visible in app on start date">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                        <div className="vr"></div>
                                    </div>
                                    <DatePicker
                                        allowClear={false}
                                        format="YYYY-MM-DD hh:mm A"
                                        showTime={{
                                            format: 'HH:mm',
                                            minuteStep: 15,
                                        }}
                                        defaultValue={moment().endOf('day')} // should set the default to midnight but doesnt do it
                                        value={formik.values.startDate}
                                        onChange={(e) =>
                                            formik.setFieldValue('startDate', e)
                                        }
                                        disabledDate={(d) =>
                                            fileSelected &&
                                            (!d || d.isSameOrBefore(moment()))
                                        }
                                    />
                                </div>

                                <div className="text_center inline_block">
                                    <div className="light_label">
                                        <ErrorAwareLabel
                                            text="Activatable"
                                            hasError={checkIfHasError(
                                                `activatableDate`
                                            )}
                                        />
                                        &nbsp;
                                        <Tooltip title="If promotion has an activatable date it will be 'upcoming' from start date until activatable date is reached">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                        <div className="vr"></div>
                                    </div>
                                    <DatePicker
                                        allowClear={true}
                                        format="YYYY-MM-DD hh:mm A"
                                        showTime={{
                                            format: 'HH:mm',
                                            minuteStep: 15,
                                        }}
                                        value={formik.values.activatableDate}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'activatableDate',
                                                e
                                            )
                                        }
                                        disabledDate={(d) =>
                                            !d ||
                                            d.isSameOrBefore(
                                                formik.values.startDate
                                            )
                                        }
                                        disabled={disableDate('Activable')}
                                    />
                                </div>

                                <div className="text_center inline_block">
                                    <div className="light_label">
                                        <ErrorAwareLabel
                                            text="Inactivatable"
                                            hasError={checkIfHasError(
                                                `inactivatableDate`
                                            )}
                                        />
                                        &nbsp;
                                        <Tooltip title="If promotion has an inactivatable date it will be 'expired' from inactivatable date until end date is reached">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                        <div className="vr"></div>
                                    </div>
                                    <DatePicker
                                        allowClear={true}
                                        format="YYYY-MM-DD hh:mm A"
                                        showTime={{
                                            format: 'HH:mm',
                                            minuteStep: 15,
                                        }}
                                        value={formik.values.inactivatableDate}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'inactivatableDate',
                                                e
                                            )
                                        }
                                        disabledDate={(d) =>
                                            !d ||
                                            (formik.values.activatableDate
                                                ? d.isSameOrBefore(
                                                      formik.values
                                                          .activatableDate
                                                  )
                                                : d.isSameOrBefore(
                                                      formik.values.startDate
                                                  ))
                                        }
                                        disabled={disableDate('Inactivable')}
                                    />
                                </div>

                                <div className="text_center inline_block">
                                    <div className="light_label">
                                        <ErrorAwareLabel
                                            text="End"
                                            hasError={checkIfHasError(
                                                `endDate`
                                            )}
                                        />
                                        &nbsp;
                                        <Tooltip title="Promotion becomes invisible in app on end date">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                        <div className="vr"></div>
                                    </div>
                                    <DatePicker
                                        allowClear={false}
                                        format="YYYY-MM-DD hh:mm A"
                                        showTime={{
                                            format: 'HH:mm',
                                            minuteStep: 15,
                                        }}
                                        value={formik.values.endDate}
                                        onChange={(e) =>
                                            formik.setFieldValue('endDate', e)
                                        }
                                        disabledDate={(d) =>
                                            !d ||
                                            (formik.values.activatableDate
                                                ? d.isSameOrBefore(
                                                      formik.values
                                                          .inactivatableDate
                                                  )
                                                : formik.values.activatableDate
                                                ? d.isSameOrBefore(
                                                      formik.values
                                                          .activatableDate
                                                  )
                                                : d.isSameOrBefore(
                                                      formik.values.startDate
                                                  ))
                                        }
                                        disabled={disableDate('End')}
                                    />
                                </div>
                            </div>
                        </Card>

                        <h3 className={style.sectionHeader}>Activation Time</h3>
                        <Card style={{ width: '100%' }}>
                            <div className={`${style.hours_holder_header}`}>
                                {formik.values.type === 'in_store' && (
                                    <h5
                                        className={
                                            style.date_holder_header_in_store_message
                                        }
                                    >
                                        Available only for type coupon
                                    </h5>
                                )}
                            </div>
                            <div
                                className={
                                    style.recurring_hours_and_labels_container
                                }
                            >
                                <div
                                    className={`${style.recurring_hours_holder} dates_holder`}
                                >
                                    <div className="text_center inline_block">
                                        <div className="light_label">
                                            <ErrorAwareLabel
                                                text="Hour Start"
                                                hasError={checkIfHasError(
                                                    `startPeriod`
                                                )}
                                            />
                                            &nbsp;
                                            <Tooltip title="Promotion becomes activable starting at this time, each days between the activable start day and end day">
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                            <div className="vr"></div>
                                        </div>
                                        {
                                            <Select
                                                defaultValue={undefined}
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'startPeriod',
                                                        e
                                                    )
                                                }
                                                value={
                                                    formik.values.startPeriod ||
                                                    'Select'
                                                }
                                                disabled={
                                                    formik.values.type ===
                                                    'in_store'
                                                }
                                                style={{
                                                    width: 100,
                                                }}
                                            >
                                                {hours.map((hour) => (
                                                    <Select.Option
                                                        value={hour}
                                                        key={hour}
                                                    >
                                                        {hour}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        }
                                    </div>

                                    <div className="text_center inline_block">
                                        <div className="light_label">
                                            <ErrorAwareLabel
                                                text="Hour End"
                                                hasError={checkIfHasError(
                                                    `endPeriod`
                                                )}
                                            />
                                            &nbsp;
                                            <Tooltip
                                                title="Promotion becomes non-activable starting at this time, 
                                                        each days between the activable start day and inactivatable end day"
                                            >
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                            <div className="vr"></div>
                                        </div>
                                        {
                                            <Select
                                                defaultValue={undefined}
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'endPeriod',
                                                        e
                                                    )
                                                }
                                                value={
                                                    formik.values.endPeriod ||
                                                    'Select'
                                                }
                                                style={{
                                                    width: 100,
                                                }}
                                                disabled={
                                                    formik.values.type ===
                                                    'in_store'
                                                }
                                            >
                                                {hours.map((hour) => (
                                                    <Select.Option
                                                        value={hour}
                                                        key={hour}
                                                    >
                                                        {hour}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        }
                                    </div>
                                </div>

                                <div
                                    className={`${style.labels_outside_active_time} inline_block`}
                                >
                                    {formik.values.items?.map((item, i) => (
                                        <div
                                            className="text_center inline_block"
                                            key={item.language}
                                        >
                                            <div
                                                className={
                                                    style.fluid_input_col
                                                }
                                            >
                                                <div className="small_label light_label">
                                                    <ErrorAwareLabel
                                                        text="Outside Active Hours"
                                                        hasError={checkIfHasError(
                                                            `outsideActivatableHours`
                                                        )}
                                                    />
                                                    <span
                                                        className={`${style.outside_active_hour_lang_label}`}
                                                    >
                                                        {item.language.toUpperCase()}
                                                    </span>
                                                    <div className="vr"></div>
                                                </div>

                                                <Input
                                                    value={
                                                        item.outsideActivatableHours ??
                                                        'Outside active time'
                                                    }
                                                    name={`items[${i}].outsideActivatableHours`}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    disabled={
                                                        formik.values.type ===
                                                        'in_store'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Card>

                        {/* Offer Details */}

                        <h3 className={style.sectionHeader}>Offer Details</h3>
                        <Card className={style.card_container}>
                            <div
                                className={style.input_row}
                                style={{ width: '100%' }}
                            >
                                {' '}
                                <div className={style.top_label_input}>
                                    <span>Type selection</span>
                                    <Select
                                        placeholder="Promotion type"
                                        style={{ width: 200 }}
                                        value={formik.values.type}
                                        onChange={onTypeChange}
                                    >
                                        <Select.Option
                                            value={PromotionTypes[0]}
                                        >
                                            {getPromotionsTypeName(
                                                PromotionTypes[0]
                                            )}
                                        </Select.Option>
                                        <Select.Option
                                            value={PromotionTypes[1]}
                                        >
                                            {getPromotionsTypeName(
                                                PromotionTypes[1]
                                            )}
                                        </Select.Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={`${style.input_row}`}>
                                <div className={style.top_label_input}>
                                    <ErrorAwareLabel
                                        text="Category"
                                        hasError={checkIfHasError(`categoryId`)}
                                    />
                                    <Select
                                        loading={
                                            categoryStore.isFetchingActiveCategories
                                        }
                                        style={{ width: 200 }}
                                        defaultValue={undefined}
                                        onChange={setCategory}
                                        value={formik.values.category?.id}
                                    >
                                        {categoryStore.activeCategories?.results.map(
                                            (category) => (
                                                <Select.Option
                                                    value={category.id}
                                                    key={category.id}
                                                >
                                                    {category.items[0].title}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </div>
                                <div className={style.top_label_input}>
                                    <span>Promotional Offer</span>
                                    <Select
                                        style={{ width: 200 }}
                                        defaultValue={undefined}
                                        onChange={(id) => {
                                            formik.setFieldValue(
                                                'promotionalOffer',
                                                offerStore.getOffersById(id) ??
                                                    null
                                            );
                                            formik.setFieldValue(
                                                'promotionalOfferId',
                                                id ?? null
                                            );
                                        }}
                                        allowClear={true}
                                        value={
                                            formik.values.promotionalOffer?.id
                                        }
                                    >
                                        {offerStore.offers?.results.map(
                                            (offer) => (
                                                <Select.Option
                                                    value={offer.id}
                                                    key={offer.id}
                                                >
                                                    {offer.items[0].title}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </div>

                                <div className={style.top_label_input}>
                                    <span>Discount Value</span>
                                    <Input
                                        type="number"
                                        prefix="$"
                                        value={formik.values.discountValue ?? 0}
                                        name="discountValue"
                                        onChange={formik.handleChange}
                                    />
                                </div>

                                <div className={style.top_label_input}>
                                    <span>DeepLink</span>
                                    <Input
                                        type="text"
                                        prefix=""
                                        value={formik.values.deepLink ?? ''}
                                        name="deepLink"
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className={`${style.input_row}`}>
                                {formik.values.items?.map((item, i) => (
                                    <div
                                        key={item.language}
                                        className={style.config_container}
                                    >
                                        <div className={style.items_defs_box}>
                                            <div
                                                className={style.items_content}
                                            >
                                                <div
                                                    className={style.input_row}
                                                    style={{
                                                        flexWrap: 'nowrap',
                                                    }}
                                                >
                                                    <div key={item.language}>
                                                        <ErrorAwareLabel
                                                            text={`Foreground ${item.language}`}
                                                        />
                                                        <ImageInput
                                                            url={item.thumbnail}
                                                            onChange={(url) =>
                                                                formik.setFieldValue(
                                                                    `items[${i}].thumbnail`,
                                                                    url
                                                                )
                                                            }
                                                            onDelete={() =>
                                                                formik.setFieldValue(
                                                                    `items[${i}].thumbnail`,
                                                                    null
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div key={item.language}>
                                                        <ErrorAwareLabel
                                                            text={`Background ${item.language}`}
                                                        />
                                                        <ImageInput
                                                            url={
                                                                item.background
                                                            }
                                                            onChange={(url) =>
                                                                formik.setFieldValue(
                                                                    `items[${i}].background`,
                                                                    url
                                                                )
                                                            }
                                                            onDelete={() =>
                                                                formik.setFieldValue(
                                                                    `items[${i}].background`,
                                                                    null
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* Titles */}
                                                <div
                                                    className={style.input_row}
                                                >
                                                    <div
                                                        key={item.language}
                                                        className={
                                                            style.fixed_width_input_col
                                                        }
                                                    >
                                                        <ErrorAwareLabel
                                                            text={`Title ${item.language}`}
                                                            hasError={checkIfHasError(
                                                                `items[${i}].title`
                                                            )}
                                                        />
                                                        <Input
                                                            name={`items[${i}].title`}
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            onBlur={
                                                                formik.handleBlur
                                                            }
                                                            value={item.title}
                                                        />
                                                    </div>
                                                </div>
                                                {/* SubTitle */}
                                                <div
                                                    className={style.input_row}
                                                >
                                                    <div
                                                        key={item.language}
                                                        className={
                                                            style.fixed_width_input_col
                                                        }
                                                    >
                                                        <ErrorAwareLabel
                                                            text={`Sub title ${item.language}`}
                                                            hasError={checkIfHasError(
                                                                `items[${i}].subTitle`
                                                            )}
                                                        />
                                                        <Input
                                                            name={`items[${i}].subTitle`}
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            onBlur={
                                                                formik.handleBlur
                                                            }
                                                            value={
                                                                item.subTitle
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* Description */}
                                                <div
                                                    className={style.input_row}
                                                >
                                                    <div
                                                        className={
                                                            style.input_row
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                style.fixed_width_input_col
                                                            }
                                                        >
                                                            <ErrorAwareLabel
                                                                text={`Description ${item.language}`}
                                                                hasError={checkIfHasError(
                                                                    `items[${i}].description`
                                                                )}
                                                            />
                                                            <RichProvider>
                                                                <RichTextEditor
                                                                    onEditorStateChange={(
                                                                        x
                                                                    ) =>
                                                                        formik.setFieldValue(
                                                                            `items[${i}].description`,
                                                                            x
                                                                        )
                                                                    }
                                                                    editorState={
                                                                        item.description
                                                                    }
                                                                />
                                                            </RichProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {/* Coupon related infos */}
                                {formik.values.type === 'coupon' && (
                                    <div className={style.input_row}>
                                        <div className={style.top_label_input}>
                                            <span className="left_label">
                                                Activation code
                                            </span>
                                            <Input
                                                value={
                                                    formik.values
                                                        .activationCode ?? ''
                                                }
                                                name="activationCode"
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className={style.top_label_input}>
                                            <span>Quantity</span>
                                            <Input
                                                type="number"
                                                value={
                                                    formik.values.quantity ?? 0
                                                }
                                                name="quantity"
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>

                        {/* Weather */}

                        <h3 className={style.sectionHeader}>
                            Weather Activation
                        </h3>

                        <Card
                            className={style.input_row}
                            style={{ width: '100%' }}
                        >
                            <div className={style.input_row}>
                                <div className={style.fluid_input_col}>
                                    <span>Floor</span>
                                    <Input
                                        type="number"
                                        prefix=""
                                        suffix="°C"
                                        value={
                                            formik.values
                                                .temperatureActivationFloor ??
                                            ''
                                        }
                                        name="temperatureActivationFloor"
                                        onChange={(event) => {
                                            const inputValue =
                                                event.target.value;
                                            console.log(inputValue);
                                            formik.setFieldValue(
                                                'temperatureActivationFloor',
                                                inputValue === ''
                                                    ? null
                                                    : inputValue
                                            );
                                        }}
                                    />
                                </div>
                                <div className={style.fluid_input_col}>
                                    <span>Ceiling</span>
                                    <Input
                                        type="number"
                                        prefix=""
                                        value={
                                            formik.values
                                                .temperatureActivationCeiling ??
                                            ''
                                        }
                                        name="temperatureActivationCeiling"
                                        suffix="°C"
                                        onChange={(event) => {
                                            const inputValue =
                                                event.target.value;
                                            console.log(inputValue);
                                            formik.setFieldValue(
                                                'temperatureActivationCeiling',
                                                inputValue === ''
                                                    ? null
                                                    : inputValue
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </Card>

                        <h3 className={style.sectionHeader}>Stats</h3>
                        <Card className={style.card_container}>
                            <div className={style.input_row}>
                                {formik.values.isPrivate && (
                                    <div className={`${style.fluid_input_col}`}>
                                        <Card>
                                            <Statistic
                                                className={style.stats}
                                                title="Won"
                                                value={
                                                    formik.values.totalGrants ||
                                                    0
                                                }
                                            />
                                        </Card>
                                    </div>
                                )}
                                <div className={`${style.fluid_input_col}`}>
                                    <Card>
                                        <Statistic
                                            className={style.stats}
                                            title="Activated"
                                            value={
                                                formik.values
                                                    .totalActivations || 0
                                            }
                                        />
                                    </Card>
                                </div>
                                <div className={`${style.fluid_input_col}`}>
                                    <Card>
                                        <Statistic
                                            className={style.stats}
                                            title="Cost"
                                            prefix="$"
                                            value={
                                                (formik.values
                                                    .totalActivations &&
                                                    formik.values
                                                        .discountValue &&
                                                    formik.values
                                                        .totalActivations *
                                                        formik.values
                                                            .discountValue) ||
                                                0
                                            }
                                        />
                                    </Card>
                                </div>
                            </div>
                        </Card>

                        <Divider orientation="left" className={style.divider}>
                            Winnable
                        </Divider>
                        {/* Other fields */}
                        <div className={style.input_row}>
                            <div className={style.fluid_input_col}>
                                <span className="left_label">Is winnable</span>
                                <Switch
                                    checked={formik.values.isPrivate ?? false}
                                    onChange={(e) =>
                                        formik.setFieldValue('isPrivate', e)
                                    }
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </div>
                        </div>

                        {formik.values.isPrivate && (
                            <div className={style.winnable_buttons_row}>
                                <div className={style.fluid_input_col}>
                                    <span className="left_label">
                                        Email list
                                    </span>
                                    <ButtonFileInput
                                        onFileSelected={onFileSelected}
                                        accept=".csv"
                                        isLoading={isUploading}
                                        disabled={disableUpload()}
                                    />
                                </div>
                                <div className={style.fluid_input_col}>
                                    <Button
                                        onClick={() => downloadWinners()}
                                        type="primary"
                                    >
                                        Download list
                                    </Button>
                                </div>
                            </div>
                        )}

                        {formik.values.isPrivate && (
                            <div className={style.winners_box}>
                                <Tabs defaultActiveKey="1">
                                    <Tabs.TabPane
                                        tab={`Registered Winners (${
                                            grants?.length ?? 0
                                        })`}
                                        key="1"
                                    >
                                        {isFetching ? (
                                            <Spin />
                                        ) : (
                                            <Table<Grant>
                                                rowKey="id"
                                                columns={columnsNormal}
                                                dataSource={grants ?? undefined}
                                                pagination={false}
                                            />
                                        )}
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={`Anonymous Winners (${
                                            anonymousGrants?.length ?? 0
                                        })`}
                                        key="2"
                                    >
                                        {isFetchingAnon ? (
                                            <Spin />
                                        ) : (
                                            <Table<AnonymousGrant>
                                                rowKey="id"
                                                columns={columnsAnonymus}
                                                dataSource={
                                                    anonymousGrants ?? undefined
                                                }
                                                pagination={false}
                                            />
                                        )}
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        )}
                    </div>
                    <div className={style.preview_container}>
                        <h3 className={style.sectionHeader}>App Preview</h3>
                        <AcvtCanvas changeLang={changeLanguage}>
                            <Acvt>
                                <>
                                    <AcvtPromotionPage
                                        key={formik.values.id}
                                        language={lang}
                                        promoObject={promoObjectData}
                                        opened={setIsCardOpened}
                                        onEditMode={true}
                                    />
                                    <AcvtBottomTabBar
                                        language={lang}
                                        tab={selectedTab}
                                        tabSelected={setSelectedTab}
                                        onEditMode={true}
                                    />
                                </>
                            </Acvt>
                        </AcvtCanvas>
                    </div>
                </div>
            </form>
        </div>
    );
});
