import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './root';
import * as yup from 'yup';
import { ParsedStore } from '../helpers/store-helper';
import { NotificationManager } from '../components';
import { HttpMethod } from '../types';

const StoreSchema = yup.object({
    id: yup.number().required(),
    nref: yup.string().required(),
    title: yup.string().notRequired().nullable(),
    lat: yup.number().notRequired().nullable(),
    lng: yup.number().notRequired().nullable(),
    img: yup.string().notRequired().nullable(),
    tel: yup.string().notRequired().nullable(),
    hours: yup.array().of(yup.string().nullable()).required(),
    services: yup.array(yup.string().required()).required(),
    status: yup.string().required(),
    addr: yup
        .object({
            street: yup.string().notRequired().nullable(),
            city: yup.string().notRequired().nullable(),
            post: yup.string().notRequired().nullable(),
        })
        .required(),
});

const FetchStoresSchema = yup.object({
    stores: yup.array(StoreSchema).required(),
});

export type Store = yup.InferType<typeof StoreSchema>;

export class StoresStore {
    @observable stores: Store[] | null = null;
    @observable parsedStore: ParsedStore[] | null = null;
    @observable isFetchingStores = false;
    @observable isUpdatingStores = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    setParsedStore(parsedStore: ParsedStore[]) {
        this.parsedStore = parsedStore;
    }

    @action
    async uploadChanges(payload: {
        additions: Store[];
        modifications: Store[];
    }): Promise<boolean> {
        if (this.isUpdatingStores) {
            return false;
        }

        this.isUpdatingStores = true;
        const response = await this.rootStore.makeNetworkCall({
            method: HttpMethod.PUT,
            url: '/stores',
            data: payload,
        });
        this.isUpdatingStores = false;

        if (!response.err) {
            NotificationManager.showSuccess('Stores updated!');
            return true;
        }

        return false;
    }

    @action
    async fetchStores(this: StoresStore) {
        if (this.isFetchingStores) {
            return;
        }

        this.isFetchingStores = true;
        const response = await this.rootStore.makeNetworkCall(
            {
                method: HttpMethod.GET,
                url: '/stores',
            },
            FetchStoresSchema
        );
        this.isFetchingStores = false;

        if (!response.err) {
            this.stores = response.data.stores;
        }
    }
}
