import React from 'react';
import style from './style.module.scss';

export const UserSingleDetail = (props: {
    title: React.ReactNode;
    info?: React.ReactNode | string | number | null;
    buttonText?: string;
    buttonFunctionality?: () => void;
    style?: React.CSSProperties;
}) => {
    return (
        <>
            <div className={style.container} style={props.style}>
                <div className={style.title}>{props.title}</div>
                <div
                    className={
                        props.buttonFunctionality && props.buttonText
                            ? style.info_and_button
                            : style.info
                    }
                >
                    {props.info ? props.info : '-'}
                </div>
                {props.buttonText && props.buttonFunctionality && (
                    <button
                        className={style.button}
                        onClick={props.buttonFunctionality}
                    >
                        {props.buttonText}
                    </button>
                )}
            </div>
        </>
    );
};
