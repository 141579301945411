import React from 'react';
import style from './style.module.scss';

interface Props {
    text: string;
    hasError?: boolean;
}

export const ErrorAwareLabel = (props: Props) => {
    return (
        <span className={props.hasError ? style.has_error : ''}>
            {props.text}
        </span>
    );
};
