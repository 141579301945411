import React from 'react';
import { CmsUserHistory } from '../../types';
import style from './style.module.scss';
import moment from 'moment';
import { Modal, Table } from 'antd';
import { Text } from '../../utils/text';

interface CmsUserHistoryProps {
    cmsUserHistory: CmsUserHistory[];
}

export const CmsUserHistoryHeader = (props: CmsUserHistoryProps) => {
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);

    const lastUpdated = props.cmsUserHistory?.length
        ? props.cmsUserHistory.reduce((a, b) =>
              a.createdAt > b.createdAt ? a : b
          )
        : null;

    const createdBy = props.cmsUserHistory
        ? props.cmsUserHistory.find((creator) => creator.action === 'create')
        : null;

    const renderDates = (_: unknown, record: CmsUserHistory) => {
        return <div>{moment(record.createdAt).format('LLLL')}</div>;
    };

    const columns = [
        {
            title: Text.cmsUserhistoryComponent.columns.email,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: Text.cmsUserhistoryComponent.columns.action,
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: Text.cmsUserhistoryComponent.columns.date,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: renderDates,
        },
    ];

    return (
        <div>
            <div className={style.history_header}>
                <div
                    onClick={() => setModalVisible(true)}
                    style={{ margin: '0' }}
                >
                    {createdBy && (
                        <span>{`Created by ${createdBy.email} at ${moment(
                            createdBy.createdAt
                        ).format('LLLL')}. `}</span>
                    )}
                    {lastUpdated && (
                        <span>
                            {`Last modified by ${lastUpdated.email} at ${moment(
                                lastUpdated.createdAt
                            ).format('LLLL')} `}
                        </span>
                    )}
                </div>
            </div>
            <Modal
                visible={isModalVisible}
                title={Text.cmsUserhistoryComponent.title}
                footer={null}
                onCancel={() => setModalVisible(false)}
            >
                <Table
                    dataSource={props.cmsUserHistory}
                    columns={columns}
                    pagination={false}
                ></Table>
            </Modal>
        </div>
    );
};
