import React from 'react';
import style from './style.module.scss';
import { ReactComponent as CalendarIcon } from '../../../icons/CalendarBlank.svg';
import { ReactComponent as GenderIcon } from '../../../icons/GenderIntersex.svg';
import { ReactComponent as MapPinIcon } from '../../../icons/MapPin.svg';
import { ReactComponent as StarIcon } from '../../../icons/Star.svg';
import { ReactComponent as UpdatedIcon } from '../../../icons/ArrowsCounterClockwise.svg';
import { ReactComponent as AppleIcon } from '../../../icons/AppleLogo.svg';
import { ReactComponent as FacebookIcon } from '../../../icons/FacebookLogo.svg';
import { ReactComponent as InvalidIcon } from '../../../icons/Prohibit.svg';
import { ReactComponent as LockIcon } from '../../../icons/LockSimple.svg';
import { ReactComponent as DropIcon } from '../../../icons/Drop.svg';
import { ReactComponent as CircleIcon } from '../../../icons/Circle.svg';
import { ReactComponent as CreditIcon } from '../../../icons/CreditCard.svg';
import { ReactComponent as StackIcon } from '../../../icons/Stack.svg';
import { Client, DeviceAppVersion } from '../../../stores/client';
import { UserSingleDetail } from '../user-single-detail';
import { Spin } from 'antd';

export const UserDetailsTab = (props: {
    clientObject?: Client;
    resetPassword?: () => void;
    paymentMethod?: {
        loading: boolean;
        data: string | null;
    };
    deviceAppVersion?: {
        loading: boolean;
        data: DeviceAppVersion | null;
    };
}) => {
    return (
        <>
            <div className={style.details_tab}>
                <UserSingleDetail
                    title={
                        <>
                            <CalendarIcon />
                            Date of birth
                        </>
                    }
                    info={props.clientObject?.birthday?.format('LL')}
                    style={{ paddingTop: '0px' }}
                />
                <UserSingleDetail
                    title={
                        <>
                            <GenderIcon />
                            Gender
                        </>
                    }
                    info={props.clientObject?.gender}
                />
                <UserSingleDetail
                    title={
                        <>
                            <MapPinIcon />
                            Postal Code
                        </>
                    }
                    info={props.clientObject?.postalCode}
                />
                <UserSingleDetail
                    title={
                        <>
                            <StarIcon />
                            Created
                        </>
                    }
                    info={props.clientObject?.dateCreated?.format('LL')}
                />
                <UserSingleDetail
                    title={
                        <>
                            <UpdatedIcon />
                            Last updated
                        </>
                    }
                    info={props.clientObject?.dateUpdated?.format('LLL')}
                />
                <UserSingleDetail
                    title={
                        <>
                            <AppleIcon />
                            Apple ID
                        </>
                    }
                    info={props.clientObject?.appleId}
                />
                <UserSingleDetail
                    title={
                        <>
                            <FacebookIcon />
                            Facebook
                        </>
                    }
                    info={props.clientObject?.facebookId}
                />
                <UserSingleDetail
                    title={
                        <>
                            <InvalidIcon />
                            Invalid logins
                        </>
                    }
                    info={props.clientObject?.invalidLoginCount}
                />
                <UserSingleDetail
                    title={
                        <>
                            <LockIcon />
                            Locked at
                        </>
                    }
                    info={props.clientObject?.lockedAt?.format('LLL')}
                    buttonText="Reset Password"
                    buttonFunctionality={props.resetPassword}
                />
                <UserSingleDetail
                    title={
                        <>
                            <DropIcon />
                            Liquid barcode ID
                        </>
                    }
                    info={props.clientObject?.liquidBarcodeId}
                />
                <UserSingleDetail
                    title={
                        <>
                            <CircleIcon />
                            NGRP
                        </>
                    }
                    info={props.clientObject?.ngrpCustomerId}
                />
                <UserSingleDetail
                    title={
                        <>
                            <CreditIcon />
                            Payment method
                        </>
                    }
                    info={
                        props.paymentMethod?.loading ? (
                            <Spin />
                        ) : (
                            props.paymentMethod?.data
                        )
                    }
                />
                <UserSingleDetail
                    title={
                        <>
                            <StackIcon />
                            App version
                        </>
                    }
                    info={
                        props.deviceAppVersion?.loading ? (
                            <Spin />
                        ) : (
                            `${
                                props.deviceAppVersion?.data?.appVersion || '-'
                            } ${props.deviceAppVersion?.data?.os || '-'}`
                        )
                    }
                />
            </div>
        </>
    );
};
